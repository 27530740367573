import { mapGetters, mapActions } from 'vuex'

const moduleName = 'sundrycreditors'

export default {
  computed: {
    ...mapGetters(moduleName, {
      returnRoute: 'returnRoute',
      snapshots: 'snapshots',
      baseSnapshot: 'baseSnapshot',
      currentSnapshot: 'currentSnapshot',
      snapshotDiff: 'snapshotDiff',
      readOnlyView: 'readOnlyView'
    }),
    ...mapGetters(['licenceExpired'])
  },
  methods: {
    ...mapActions(moduleName, ['getStoreItem', 'addStoreItem', 'saveSnapshot', 'clearSnapshots', 'setReturnRoute', 'setReadOnlyView']),
    ...mapActions('vendors', {setVendorReturnRoute: 'setReturnRoute',addStoreVendor: 'addStoreItem',editStoreVendor: 'editStoreItem'}),
    ...mapActions('contacts', {setContactReturnRoute: 'setReturnRoute',addStoreContact: 'addStoreItem',editStoreContact: 'editStoreItem'}),
    ...mapActions('printpreview', { setPrintPreviewReturnRoute: 'setReturnRoute', addEmailer: 'addEmailer', removeEmailer: 'removeEmailer' }),
    ...mapActions('printpreview', { addReportParameters: 'addParameters', removeReportParameters: 'removeParameters' })
  }
}
