<template>
  <div class="level"
    style="height: 80vh;">
    <div class="level-item">
      <i class="mdi mdi-spin mdi-loading"
        aria-hidden="true" />
      <span>&nbsp; Preparing new sundry creditor...</span>
    </div>
  </div>
</template>
<script>
import StoreMixin from './storeMixin'

export default {
  mixins: [StoreMixin],
  async created() {
    this.addStoreItem()
  }
}
</script>
